
import { defineComponent } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import EUIDateAndTime from "@/views/resources/documentation/element-ui/form/date-time-picker/DateAndTime.vue";
import EUIDateAndTimeRange from "@/views/resources/documentation/element-ui/form/date-time-picker/DateAndTimeRange.vue";
import EUIDefaultTimeValueForStartDateAndEndDate from "@/views/resources/documentation/element-ui/form/date-time-picker/DefaultTimeValueForStartDateAndEndDate.vue";

export default defineComponent({
  name: "date-time-picker",
  components: {
    EUIDateAndTime,
    EUIDateAndTimeRange,
    EUIDefaultTimeValueForStartDateAndEndDate
  },
  setup() {
    setCurrentPageTitle("DateTimePicker");
  }
});
